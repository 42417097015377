import React, { useContext } from "react";
import { Button, Table, Card } from "react-bootstrap";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useTranslation } from "react-i18next";

function UpdatesPage() {
  // Datos de ejemplo dentro del componente
  const data = {
    elements: [
      {
        version: "v.0.1",
        fechaDisponibilidad: "2024-02-10",
        versionActual: "",
      },
      {
        version: "v.1.0",
        fechaDisponibilidad: "2024-07-15",
        versionActual: "tick",
      },
      {
        version: "v.1.1",
        fechaDisponibilidad: "2024-08-01",
        versionActual: "button",
      },
      {
        version: "v.1.2",
        fechaDisponibilidad: "2024-11-01",
        versionActual: "",
      },
    ],
  };

  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const tickIcon = icon({ name: "check", style: "solid" });
  return (
    <Card>
      <Card.Header className="d-flex align-items-center justify-content-between ps-3 bg-white">
        <span>{t("updates")}</span>
      </Card.Header>

      <Card.Body>
        <div>
          <Table hover responsive>
            <thead>
              <tr>
                <th>{t("Versión")}</th>
                <th>{t("Fecha de Disponibilidad")}</th>
                <th>{t("Versión Actual")}</th>
              </tr>
            </thead>
            <tbody>
              {user
                ? data.elements.map((element, index) => (
                    <tr key={index}>
                      <td>{element.version}</td>
                      <td>{element.fechaDisponibilidad}</td>
                      <td>
                        {element.versionActual === "tick" && (
                          <FontAwesomeIcon
                            icon={tickIcon}
                            style={{ color: "green" }}
                          />
                        )}
                        {element.versionActual === "button" && (
                          <Button variant="primary">{t("Solicitar")}</Button>
                        )}
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
}

export default UpdatesPage;
