import React, { useState } from "react";
import {
  Card,
  Col,
  Form,
  Row,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { PaypalLogo, Eye, EyeSlash, Copy } from "@phosphor-icons/react";

const IntegrationsPage = () => {
  const [isSandbox, setIsSandbox] = useState(true);

  // Estados para controlar la visibilidad de las claves
  const [showSandboxPublicKey, setShowSandboxPublicKey] = useState(false);
  const [showSandboxSecretKey, setShowSandboxSecretKey] = useState(false);
  const [showProductionPublicKey, setShowProductionPublicKey] = useState(false);
  const [showProductionSecretKey, setShowProductionSecretKey] = useState(false);

  // Claves de ejemplo
  const sandboxPublicKey =
    "AQVqaUzxwkM9QYOIL3OiI7DJS4YBNsY1eZ_OUvWxJjkCjyPrtUr7ThYh9KN735rQebURyhCpBHIs3FfK";
  const sandboxSecretKey = "EHdgqnCY6-AYf-C9RQELZpycpRsnDrEpEXEQPdlmAuz_9MofFdkizPcqlRquxdyV69um224LX6JCTa-F";
  const productionPublicKey =
    "AQVqaUzxwkM9QYOIL3OiI7DJS4YBNsY1eZ_OUvWxJjkCjyPrtUr7ThYh9KN735rQebURyhCpBHIs3FfK";
  const productionSecretKey = "EHdgqnCY6-AYf-C9RQELZpycpRsnDrEpEXEQPdlmAuz_9MofFdkizPcqlRquxdyV69um224LX6JCTa-F";

  // Función para copiar clave al portapapeles
  const copyToClipboard = (key: string) => {
    navigator.clipboard.writeText(key);
    alert("Clave copiada al portapapeles");
  };

  return (
    <Row className="justify-content-center mt-5">
      <Col md={4}>
        {" "}
        {/* Ajustamos el tamaño de la columna para ocupar menos espacio */}
        <Card className="text-center p-3">
          {" "}
          {/* Añadimos padding para que la tarjeta sea más compacta */}
          <Card.Body>
            <div className="d-flex flex-column align-items-center mb-4">
              {/* Icono de PayPal */}
              <PaypalLogo size={40} className="mb-2" />{" "}
              {/* Icono más pequeño y margen inferior */}
              <h5>PayPal</h5> {/* Texto debajo del icono */}
            </div>

            {/* Apartado de Sandbox */}
            <Card.Title className="mt-4">Sandbox</Card.Title>
            <Card.Text>Clave Pública:</Card.Text>
            <InputGroup className="mb-3">
              <FormControl
                type={showSandboxPublicKey ? "text" : "password"}
                value={sandboxPublicKey}
                readOnly
              />
              <Button
                variant="outline-secondary"
                onClick={() => setShowSandboxPublicKey(!showSandboxPublicKey)}
              >
                {showSandboxPublicKey ? (
                  <EyeSlash size={20} />
                ) : (
                  <Eye size={20} />
                )}
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => copyToClipboard(sandboxPublicKey)}
              >
                <Copy size={20} />
              </Button>
            </InputGroup>

            <Card.Text>Clave Secreta:</Card.Text>
            <InputGroup className="mb-3">
              <FormControl
                type={showSandboxSecretKey ? "text" : "password"}
                value={sandboxSecretKey}
                readOnly
              />
              <Button
                variant="outline-secondary"
                onClick={() => setShowSandboxSecretKey(!showSandboxSecretKey)}
              >
                {showSandboxSecretKey ? (
                  <EyeSlash size={20} />
                ) : (
                  <Eye size={20} />
                )}
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => copyToClipboard(sandboxSecretKey)}
              >
                <Copy size={20} />
              </Button>
            </InputGroup>

            {/* Separador */}
            <hr />

            {/* Apartado de Producción */}
            <Card.Title className="mt-4">Producción</Card.Title>
            <Card.Text>Clave Pública:</Card.Text>
            <InputGroup className="mb-3">
              <FormControl
                type={showProductionPublicKey ? "text" : "password"}
                value={productionPublicKey}
                readOnly
              />
              <Button
                variant="outline-secondary"
                onClick={() =>
                  setShowProductionPublicKey(!showProductionPublicKey)
                }
              >
                {showProductionPublicKey ? (
                  <EyeSlash size={20} />
                ) : (
                  <Eye size={20} />
                )}
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => copyToClipboard(productionPublicKey)}
              >
                <Copy size={20} />
              </Button>
            </InputGroup>

            <Card.Text>Clave Secreta:</Card.Text>
            <InputGroup className="mb-3">
              <FormControl
                type={showProductionSecretKey ? "text" : "password"}
                value={productionSecretKey}
                readOnly
              />
              <Button
                variant="outline-secondary"
                onClick={() =>
                  setShowProductionSecretKey(!showProductionSecretKey)
                }
              >
                {showProductionSecretKey ? (
                  <EyeSlash size={20} />
                ) : (
                  <Eye size={20} />
                )}
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => copyToClipboard(productionSecretKey)}
              >
                <Copy size={20} />
              </Button>
            </InputGroup>

            {/* Switch para cambiar entre sandbox y producción */}
            <Form className="d-flex justify-content-center align-items-center mt-4">
              <Form.Check
                type="switch"
                id="sandbox-production-switch"
                checked={isSandbox}
                onChange={() => setIsSandbox(!isSandbox)}
              />
              <Form.Label className="ms-2">
                {isSandbox ? "Modo Sandbox" : "Modo Producción"}
              </Form.Label>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default IntegrationsPage;
