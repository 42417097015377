import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/bootstrap-4";
import { useTranslation } from "react-i18next";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import StatusSelector from "@beeldit/statuses-and-actions/components/StatusSelector";
import CountryIdSelector from "@bloonde/location/countries/components/CountryIdSelector";
import StateSelector from "@bloonde/location/states/components/StateSelector";
import TagMultiSelector from "@beeldit/crm/crm-tags/components/TagMultiSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function ContactForm(props: Prop) {
  const { formRef, onSubmit, context } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t("name"),
    first_surname: t("first_surname"),
    last_surname: t("last_surname"),
    phone: t("phone"),
    email: t("email"),
    street: t("street"),
    city: t("city"),
    postal_code: t("postal_code"),
    country_id: t("country_id"),
    state_id: t("state"),
    additional_information: t("additional_information"),
    status_id: t("status"),
    tag_ids: t("tags"),
    lang: t("lang"),
  };

  let schema = {
    type: "object",
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      name: {
        type: "string",
        title: translations.name,
      },
      first_surname: {
        type: "string",
        title: translations.first_surname,
      },
      last_surname: {
        type: "string",
        title: translations.last_surname,        
      },
      lang: {        
        title: translations.lang,
        type: "string",
        default: "es",
        oneOf: [
          { title: "Español", const: "es" },
          { title: "English", const: "en" },
        ],
      },
      email: {
        type: "string",
        title: translations.email,
      },
      phone: {
        type: "string",
        title: translations.phone,
      },
      address: {
        type: "object",
        title: "",
        properties: {
          street: {
            type: "string",
            title: translations.street,
          },
          postal_code: {
            type: "string",
            title: translations.postal_code,
          },
          city: {
            type: "string",
            title: translations.city,
          },
          country_id: {
            type: "number",
            title: translations.country_id,
          },
          state_id: {},
          state: {},
        },
      },
      status_id: {
        // TODO - Hay que crear un selector de status filtrado por la clase
        type: "number",
        title: translations.status_id,
      },
      tag_ids: {
        title: translations.tag_ids,
        type: "array",
        items: {
            type: ["number", "string"],
        }
      },
      additional_information: {
        type: "string",
        title: translations.additional_information,
        format: "textarea",
      },

    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    status_id: {
      "ui:widget": "status-selector",
      statusable_type: "Beeldit\\Crm\\Models\\Contact",
    },
    address: {
      country_id: {
        "ui:widget": "country-selector",
      },
      state_id: {
        "ui:widget": "state-selector",
      },
    },  
    tag_ids:{
      "ui:widget": "tag-multi-select"
    },  
  };
  const customWidgets = {
    ...widgets,
    ...{
      "status-selector": StatusSelector,
      "country-selector": CountryIdSelector,
      "state-selector": StateSelector,
      "tag-multi-select": TagMultiSelector
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    if (
      element.address &&
      element.address.country_id &&
      element.address.country_id == 238
    ) {
      schema.properties.address.properties.state_id = {
        type: "number",
        title: translations.state_id,
        show: element.country_id == 238,
      };
      delete schema.properties.address.properties.state;
    } else if (
      element.address &&
      element.address.country_id &&
      element.address.country_id != 238
    ) {
      schema.properties.address.properties.state = {
        type: "string",
        title: translations.state_id,
        show: element.country_id != 238,
      };
      delete schema.properties.address.properties.state_id;
    }
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={finalSchema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default ContactForm;
