import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {},
  },
  es: {
    translation: {
      sign_in: "Iniciar sesión",
      email: "Email",
      password: "Contraseña",
      events: "Eventos",
      event: "Evento",
      name: "Nombre",
      capacity: "Capacidad",
      date_start: "Fecha de inicio",
      date_end: "Fecha fin",
      event_type: "Tipo",
      Actions: "Acciones",
      all_rooms: "Todas las salas",
      all_types: "Todos los tipos",
      room: "Sala",
      description: "Description",
      close: "Cerrar",
      save_changes: "Guardar",
      create_event: "Crear evento",
      edit_event: "Editar evento",
      remove_event: "Borrar evento",
      "are_you_sure_you_want_to_remove_this_event?":
        "¿Estás seguro de que quieres borrar este evento?",
      generate_event_sessions: "Generar sesiones",
      "are_you_sure_you_want_to_generete_the_sessions_of_this_events?":
        "Las sesiones no bloqueadas comprendidas entre estas dos fechas se borrarán y se autogenerarán de nuevo ¿Estás seguro de que quieres continuar?",
      event_rules: "Reglas de evento",
      create_event_rule: "Crear regla de evento",
      edit_event_rule: "Editar regla de evento",
      remove_event_rule: "Borrar regla de evento",
      "are_you_sure_you_want_to_remove_this_event_rule?":
        "¿Estás seguro de que quieres borrar esta regla de evento?",
      weekday: "Día de la semana",
      special_date: "Día",
      time_start: "Hora de inicio",
      time_end: "Hora fin",
      prices: "Precio",
      event_sessions: "Sesiones de evento",
      create_event_session: "Crear sesión de evento",
      edit_event_session: "Editar sesión de evento",
      remove_event_session: "Borrar sesión de evento",
      "are_you_sure_you_want_to_remove_this_event_session?":
        "¿Estás seguro de que quieres borrar esta sesión de evento?",
      date: "Fecha",
      price_type: "Tipo de precio",
      price: "Precio",
      all_event_rules: "Todas las reglas de evento",
      event_rule: "Regla de evento",
      bookings: "Reservas",
      create_booking: "Crear reserva",
      edit_booking: "Editar reserva",
      remove_booking: "Borrar reserva",
      "are_you_sure_you_want_to_remove_this_booking?":
        "¿Estás seguro de que quieres borrar esta reserva?",
      event_session: "Sesión de evento",
      fiscal_name: "Nombre",
      fiscal_first_surname: "Primer apellido",
      fiscal_last_surname: "Segundo apellido",
      fiscal_address: "Dirección",
      fiscal_number: "DNI",
      fiscal_zip_code: "Código postal",
      fiscal_city: "Ciudad",
      fiscal_province: "Provincia",
      fiscal_country_code: "País",
      phone: "Teléfono",
      tickets: "Tickets",
      quantity: "Cantidad",
      invoice_account: "Datos de facturación",
      total_price: "Precio total",
      status: "Estado",
      commerces: "Comercios",
      commerce: "Comercio",
      create_commerce: "Crear comercio",
      edit_commerce: "Editar comercio",
      remove_commerce: "Borrar comercio",
      "are_you_sure_you_want_to_remove_this_commerce?":
        "¿Estás seguro de que quieres borrar este comercio?",
      published: "Publicado",
      all_tenants: "Todos los tenants",
      contact_email: "Email de contacto",
      survey_link: "Link para encuesta",
      rooms: "Salas",
      create_room: "Crear sala",
      edit_room: "Editar sala",
      remove_room: "Borrar sala",
      "are_you_sure_you_want_to_remove_this_room?":
        "¿Estás seguro de que quieres borrar este sala?",
      numbered_tickets: "Tickets numerados",
      addresses: "Direcciones",
      address: "Dirección",
      create_address: "Crear dirección",
      edit_address: "Editar dirección",
      remove_address: "Borrar dirección",
      "are_you_sure_you_want_to_remove_this_address?":
        "¿Estás seguro de que quieres borrar esta dirección?",
      street: "Calle",
      city: "Ciudad",
      country: "País",
      state: "Provincia",
      postal_code: "Código postal",
      geographic_longitude: "Longitud geográfica",
      geographic_latitude: "Latitud geográfica",
      google_maps_link: "Enlace de google map",
      event_price_types: "Tipos de precio de eventos",
      event_price_type: "Tipo de precio de evento",
      create_event_price_type: "Crear tipo de precio de evento",
      edit_event_price_type: "Editar tipo de precio de evento",
      remove_event_price_type: "Borrar tipo de precio de evento",
      "are_you_sure_you_want_to_remove_this_event_price_type?":
        "¿Estás seguro de que quieres borrar este tipo de precio de evento?",
      all_addresses: "Todas las direcciones",
      none: "Ninguno",
      sunday: "Domingo",
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sábado",
      lock_event_sessions_message:
        "¿Estás seguro de querer bloquear esta sesión?",
      unlock_event_sessions_message:
        "¿Estás seguro de querer desbloquear esta sesión?",
      qr_scanner: "Validación de tickets",
      show_qr_scanner: "Mostrar escáner QR",
      hide_qr_scanner: "Ocultar escáner QR",
      check_qr: "Comprobar QR",
      booking_found: "Reserva encontrada",
      Pending: "Pendiente",
      Redeemed: "Canjeado",
      Cancelled: "Cancelado",
      Paid: "Pagado",
      no_redeemed: "no canjeado",
      booking_not_found: "Reserva no encontrada",
      booking_not_found_text:
        "No se ha encontrado ninguna reserva con ese código QR",
      main_data: "Datos principales",
      users_activity: "Registro de actividad",
      create: "Crear",
      pages: "Páginas",
      es: "Español",
      en: "Inglés",
      logout: "Cerrar sesión",
      users: "Usuarios",
      code: "Código",
      status_id: "Estado",
      type_id: "Tipo",
      initial_amount: "Cantidad",
      uses: "Usos",
      remaining_uses: "Usos restantes",
      uses_same_user: "Usos por usuario",
      min_purchase: "Compra mínima",
      start_date: "Fecha de inicio",
      end_date: "Fecha de fin",
      percentage: "Porcentaje",
      fixed: "Fijo",
      free_purchase: "Compra gratuita",
      assignables: "Rec. asignables",
      candidates: "Rec. candidatos",
      features: "Características",
      assignable_types: "Tipos de asignables",
      resources_managements: "Gestión de recursos",
      assignableTypes: "Tipos de asignables",
      create_assignableType: "Crear tipo de asignable",
      edit_assignableType: "Editar tipo de asignable",
      are_you_sure_you_want_to_remove_this_assignableType:
        "¿Estás seguro de que quieres borrar este tipo de asignable?",
      remove_assignableType: "Borrar tipo de asignable",
      create_feature: "Crear característica",
      edit_feature: "Editar característica",
      remove_feature: "Borrar característica",
      are_you_sure_you_want_to_remove_this_feature:
        "¿Estás seguro de que quieres borrar esta característica?",
      create_assignable: "Crear asignable",
      edit_assignable: "Editar asignable",
      remove_assignable: "Borrar asignable",
      are_you_sure_you_want_to_remove_this_assignable:
        "¿Estás seguro de que quieres borrar este asignable?",
      select_a_type: "Selecciona un tipo",
      assignable_type_id: "Tipo",
      previous_time: "Tiempo de antelación",
      assignment_amount: "Cantidad de asignación",
      create_candidate: "Crear candidato",
      edit_candidate: "Editar candidato",
      remove_candidate: "Borrar candidato",
      are_you_sure_you_want_to_remove_this_candidate:
        "¿Estás seguro de que quieres borrar este candidato?",
      schedule: "Horario",
      day: "Día",
      start_time: "Hora de inicio",
      duration: "Duración",
      days_off: "Días libres (Por semana)",
      are_you_sure_you_want_to_generete_the_assignments:
        "¿Estás seguro de que quieres generar las asignaciones?",
      holidays: "Vacaciones",
      participation_percentage: "Porcentaje de participación",
      assignments: "Asignaciones",
      color: "Color",
      week: "Semana",
      select_a_candidate: "Selecciona un candidato",
      articles: "Artículos",
      create_page: "Crear página",
      select_user: "Selecciona un usuario",
      user_id: "Usuario",
      configurations: "Configuración",
      coupons: "Cupones",
      header: "Cabecera",
      footer: "Pie",
      billing: "Facturación",
      create_invoice: "Crear factura",
      serial: "Serie",
      number: "Número",
      type: "Tipo",
      receiver_account: "Cuenta receptora",
      total_amount: "Cantidad total",
      reference: "Referencia",
      notes: "Notas",
      product: "Producto",
      tax_percentage: "%IVA",
      tax_amount: "IVA",
      total: "Total",
      select_an_account: "Selecciona una cuenta",
      expiration_date: "Fecha de vencimiento",
      yes: "Sí",
      no: "No",
      irpf_retention: "Retención IRPF",
      with_iva: "Con IVA",
      base_total_amount: "Base imponible",
      iva_total: "Total IVA",
      irpf_retention_total: "Total retención IRPF",
      "are_you_sure_you_want_to_generete_the_invoice_pdf?":
        "¿Estás seguro de que quieres generar el PDF de la factura?",
      invoice_accounts: "Cuentas de facturación",
      create_invoice_account: "Crear cuenta de facturación",
      edit_invoice_account: "Editar cuenta de facturación",
      fill_invoice_account_success:
        "Datos de facturación guardados correctamente, en breve recibirás un email con la factura",
      reviews: "Valoraciones",
      create_review: "Crear valoración",
      review_schema_id: "Tipo de valoración",
      communications: "Comunicaciones",
      contacts: "Contactos",
      notifications: "Notificaciones",
      first_lastname: "Primer apellido",
      create_contact: "Crear contacto",
      edit_contact: "Editar contacto",
      first_surname: "Primer apellido",
      last_surname: "Segundo apellido",
      country_id: "Pais",
      structure: "Estructura",
      content: "Contenido",
      img: "Imagen",
      url: "URL",
      cta: "CTA",
      "are_you_sure_you_want_to_test_this_email?":
        "¿Estás seguro de que quieres testear este email?",
      subject: "Asunto",
      tags: "Tags",
      lang: "Idioma",
      additional_information: "Información adicional",
      campaigns: "Campañas",
      create_campaign: "Crear campaña",
      edit_campaign: "Editar campaña",
      remove_campaign: "Borrar campaña",
      "are_you_sure_you_want_to_remove_this_campaign?":
        "¿Estás seguro de que quieres borrar esta campaña?",
      draft: "Borrador",
      in_progress: "En progreso",
      finished: "Finalizada",
      cancelled: "Cancelada",
      all_cities: "Todas las ciudades",
      cities: "Ciudades",
      countries: "Países",
      tag_ids: "Tags",
      action: "Acción",
      delay: "Retraso (horas)",
      templates: "Plantillas de notificación",
      segment: "Segmento",
      select_template: "Selecciona una plantilla",
      invoices: "Facturas",
      remove_contact: "Borrar contacto",
      "are_you_sure_you_want_to_remove_this_contact?":
        "¿Estás seguro de que quieres borrar este contacto?",
      notification_rate: "Abiertos",
      clicked_rate: "Clicks",
      report: "Informes",
      reports: "Informes",
      month: "Mes",
      year: "Año",
      hour: "Hora",
      sales: "Ventas",
      visits: "Visitas",
      configuration: "Configuración",
      add_image: "Añadir imagen",
      remove_image: "Borrar imagen",
      email_or_user_placeholder: "Introduce tu email",
      email_or_user: "Email",
      password_placeholder: "Introduce tu contraseña",
      forgot_password: "¿Olvidaste tu contraseña?",
      created_at: "Fecha",
      updates: "Actualizaciones",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
