const filterConfig = {
  type: 'object',
  properties: {
      description: {
          type: 'string',
      },
      subject_type: {
          type: 'string',
      },
      role: {
          type: 'string',
      },
      created_at_from: {
          type: 'string',
          format: 'date',
      },
      created_at_to: {
          type: 'string',
          format: 'date',
      },
    }
}
const getFiltersConfig: any = (type: string) => {
  return filterConfig;
}
export default getFiltersConfig;
